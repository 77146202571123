import React from 'react'
import { ExecutionProps } from 'styled-components'

interface GlobalStylesProps {}

const globalStylesSet = new Set<React.NamedExoticComponent<ExecutionProps>>()

export const exportGlobalStyle = (globalStyle: React.NamedExoticComponent<ExecutionProps>) => {
  globalStylesSet.add(globalStyle)
}

export const GlobalStyles: React.FC<GlobalStylesProps> = () => {
  return (
    <>
      {Array.from(globalStylesSet).map((S, i) => (
        <S key={i} />
      ))}
    </>
  )
}

export default GlobalStyles
